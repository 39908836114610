<template>
  <div>
    <section
      class="hero is-fullheight is-pulled-up"
      :class="{ 'has-background-info': $experian }"
    >
      <div class="hero-body mobile-background" :style="hero">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-widescreen is-6-desktop is-8-tablet">
              <div v-if="login">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="section">
                      <LoginForm />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="columns is-mobile is-vcentered">
                  <div class="column">
                    <p class="title is-5 has-text-white">
                      Sign up to gain access to Percayso’s valuation tools
                    </p>
                  </div>
                </div>
                <div class="box">
                  <AuthSignup />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero has-background-success-light is-small">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-vcentered is-spaced-between">
            <div class="column is-12 has-text-left">
              <p class="title is-6 has-text-weight-bold is-spaced">
                Featured in
              </p>
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/daily-mail.svg')"
                width="200"
                alt="Daily Mail"
              />
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/the-telegraph.svg')"
                width="200"
                alt="The Telegraph"
              />
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/the-times.svg')"
                width="200"
                alt="The Times"
              />
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/the-sun.svg')"
                width="100"
                alt="The Sun"
              />
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/daily-express.svg')"
                width="200"
                alt="Daily Express"
              />
            </div>
            <div class="column is-narrow">
              <img
                :src="require('core/img/logos/press/daily-star.svg')"
                width="80"
                alt="Daily Star"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { heroStyle } from 'modules/ui/services'

export default {
  name: 'RootGuest',
  components: {
    LoginForm: () => import('modules/login/components/LoginForm')
  },
  data: () => ({
    login: true,
    visible: {
      signUpForm: false
    }
  }),
  computed: {
    queryForm() {
      return this.$route.query.form
    },
    hero() {
      return this.$mq !== 'mobile' ? heroStyle() : { background: 'none' }
    }
  },
  methods: {
    toggle(section) {
      this.visible[section] = !this.visible[section]
    }
  }
}
</script>

<style lang="sass" scoped>
.columns
  &.is-spaced-between
    justify-content: space-between
  &.is-flex-end
    align-items: flex-end
  &.is-reversed
    flex-direction: row-reverse
</style>
