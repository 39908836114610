export const heroStyle = () => {
  const imgUrl =
    'url(' + require(`core/img/background/${process.env.VUE_APP_TYPE}-rotate.svg`) + ')'
  return {
    backgroundImage: imgUrl,
    backgroundSize: 'cover',
    backgroundPosition: 'right'
  }
}

export const toggleColours = () => {
  return {
    checked: '#DF4E3C',
    unchecked: '#DEDEDE'
  }
}
